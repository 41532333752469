import { Injectable } from '@angular/core';
import { CommonEnum, ModalPopupEnum, QuestionTypeListEnum, Status, difficultyLevelEnum } from '../const/constant';
import { AlertService } from './alert.service';
import { environment } from '@env/environment';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  BACKEND_IMAGE_BASE_PATH: string = environment.BACKEND_IMAGE_BASE_PATH; // backend port for image download

  constructor(private alert: AlertService) { }

  status(status?: string) {
    if (status === Status.A) {
      return Status.Active
    }
    else if(status === Status.I || status === Status.D){
      return Status.Inactive
    }
  }

   /**
   * get the error messages from store and displying in the pop up.
   * @param error is a object of errors
   */
   setErrorMessages(error: any) {
    if (typeof error?.message === CommonEnum.object) {
      this.alert.show(ModalPopupEnum.Error, error.message[0]);
    } else if (
      typeof error?.data === CommonEnum.object &&
      typeof error?.message === CommonEnum.String &&
      error?.data !== null
    ) {
      this.alert.show(ModalPopupEnum.Error, error?.message);
    } else if (
      typeof error?.data === CommonEnum.object &&
      error?.data !== null
    ) {
      this.alert.show(ModalPopupEnum.Error, error?.data[0]);
    } else if (error?.data?.message) {
      this.alert.show(ModalPopupEnum.Error, error?.data?.message);
    } else if (error?.message) {
      this.alert.show(ModalPopupEnum.Error, error?.message);
    } else {
      this.alert.show(ModalPopupEnum.Error, 'Something went wrong');
    }
  }

  // angular editor config
  angularEditorCongif() {
    return {
      editable: true,
      height: '10rem',
      // minHeight: '5rem',
      placeholder: 'Enter your answer here...',
      translate: 'yes',
      defaultParagraphSeparator: 'p',
      toolbarHiddenButtons: [
        ['insertImage', 'insertVideo', 'toggleEditorMode'],
      ],
    };
  }

  HTMLToPlainTextRegExp() {
    return new RegExp(/<[^>]*>/g);
  }

    // get the dificulty level
    questionDifficultyLevel(difficulty_level :string) {
      if (difficulty_level === difficultyLevelEnum.H) {
        return difficultyLevelEnum.Hard;
      } else if (difficulty_level === difficultyLevelEnum.M) {
        return difficultyLevelEnum.Medium;
      } else if (difficulty_level === difficultyLevelEnum.E) {
        return difficultyLevelEnum.Easy;
      } else {
        return '-';
      }
    }
  
    // get question typpe
  questionType(type:string) {
    if (type === QuestionTypeListEnum.MCQ) {
      return QuestionTypeListEnum.MCQ;
    } else if (type === QuestionTypeListEnum.TF) {
      return QuestionTypeListEnum.True_False;
    } else if (type === QuestionTypeListEnum.ES) {
      return QuestionTypeListEnum.Essay;
    } else {
      return '-';
    }
  }

    // Custom validator to check if the value is a number
    numericValidator(control: AbstractControl): ValidationErrors | null {
      const value = control.value;
      if (isNaN(value)) {
        return { 'notNumeric': true };
      }
      return null;
    }

     // Function to format the date as "22 July, 2023"
  formatDateSimple(inputDate: string): string {
    const date = new Date(inputDate);

    // Format the date as "22 July, 2023"
    return formatDate(date, 'd MMMM, y', 'en-US');
  }

    // check date if expire or not
    checkExpiration(dateTime: string): string {
      const currentDateTime = new Date();
      const providedDateTime = new Date(dateTime);
  
      if (providedDateTime <= currentDateTime) {
        return 'Expired';
      } else {
        return 'Active';
      }
    }
  
    // converting date to timestamp
    convertToTimeStamp(date: string | Date): number {
      return new Date(date).getTime();
    }
}
